.projectlink {
  border-top: 1px solid #cfcfcf;
  display: block;

  &:first-child {
    border-top: none;
  }
}

.projectblock {
  font-weight: 800;
  color: black;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding: 20px;
  }

  &:hover .rightarrow {
    transform: scale(1.125);

    @media (max-width: 640px) {
      font-size: inherit;
    }
  }

  &:hover {
    background: #f8f8f8;
  }

  img {
    padding: 0 10px;
    height: 140px;

    @media (max-width: 640px) {
      padding: 0 10px 0 0;
      height: 60px;
    }
  }

  .projectcontents {
    width: 400px;

    @media (max-width: 640px) {
      width: 300px;
    }

    .projectname {
      font-weight: 800;
      font-size: 26px;

      @media (max-width: 640px) {
        font-size: 18px;
      }
    }

    .projectsubname {
      font-weight: 400;
      font-size: 20px;

      @media (max-width: 640px) {
        font-size: 14px;
      }
    }

    .projectrole {
      font-weight: 400;
      font-style: italic;

      @media (max-width: 640px) {
        font-size: 12px;
      }
    }
  }

  .rightarrowcontainer {
    width: 140px;

    @media (max-width: 640px) {
      width: inherit;
    }
  }

  .rightarrow {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
    transition: all 0.25s;

    @media (max-width: 640px) {
      padding: 0;
      width: 30px;
    }
  }

  .border {
    border-radius: 4px;
    background: #3cb5ff;
    color: white;

    @media (max-width: 750px) {
      background: transparent;
      color: inherit;
    }
  }

  .cta {
    padding-right: 10px;
    font-size: 18px;

    @media (max-width: 750px) {
      display: none;
    }
  }

  .flexcta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}