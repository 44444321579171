.contact-page {
  min-height: 70vh;

  a {
    font-weight: 800;
    padding: 6px;
    border-radius: 4px;
    background: #3aa2ff;

    &:hover {
      color: #fff;
      background: #146fc4;
    }
  }
}