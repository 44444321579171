.talklink {
  border-top: 1px solid #cfcfcf;
  display: block;

  &:first-child {
    border-top: none;
  }
}

.talkblock {
  font-weight: 800;
  color: black;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover .rightarrow {
    font-size: 34px;

    @media (max-width: 640px) {
      font-size: inherit;
    }
  }

  &:hover {
    background: #f8f8f8;
  }

  .talkicon {
    padding-left: 10px;
    font-size: 24px;

    @media (max-width: 640px) {
      height: 80px;
      padding: 0;
    }
  }

  .red {
    color: #ff0d21;
  }

  .purple {
    color: purple;
  }

  .talkname {
    font-weight: 800;
    font-size: 20px;

    @media (max-width: 640px) {
      font-size: 18px;
    }
  }

  .talkdate {
    font-weight: 400;
    font-size: 16px;
    font-style: italic;

    @media (max-width: 640px) {
      font-size: 14px;
    }
  }

  .talkcontents {
    width: 600px;

    @media (max-width: 640px) {
      width: 80%;
    }
  }

  .rightarrow {
    width: 40px;
    padding-right: 20px;
    transition: all 0.25s;

    @media (max-width: 640px) {
      padding: 0;
      width: inherit;
    }
  }
}