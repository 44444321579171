.fourohfourheader {
  font-size: 18px;
  padding-top: 30px;

  li {
    padding: 10px 0;
  }

  a {
    font-weight: 800;
    padding: 6px;
    border-radius: 4px;
    background: #3aa2ff;

    &:hover {
      color: #fff;
      background: #146fc4;
    }
  }
}