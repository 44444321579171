.projectspage {
  h1, h2 {
    margin: 0;
    padding-bottom: 50px;
  }

  h2 {
    text-align: center;
    font-size: 45px;
    //border-bottom: 1px solid #cfcfcf;

    @media (max-width: 640px) {
      font-size: 30px;
      padding-bottom: 20px;
    }
  }

  .projectlist {

  }

  .projectgroup {
    margin-top: 90px;
    background: white;
    padding: 50px 0 0;
    border-radius: 8px;
    box-shadow: 0 2px 2px #bfbfbf;

    @media (max-width: 640px) {
      margin-top: 20px;
      padding: 20px 0 0;
    }
  }
}