html {
  box-sizing: border-box;
}
*,*:before, *:after {
  box-sizing: inherit
}

  body {
  margin: 0;
}

#root,
.App {
  height: 100%;
  font-family: proxima-nova,helvetica neue,helvetica,arial,sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #545454;
  text-rendering: optimizeSpeed;
  background: #f8f8f8;
}

.container {
  width: 90%;
  height: 100%;
  max-width: 900px;
  margin: 0 auto;
}

li {
  line-height: 2;
}

.content {
  height: 90%;
}

.thin {
  font-family: 'Oswald', sans-serif;
}

.extraSpace {
  height: 50vh;
}

a {
  color: #fff;
  text-decoration: none;

  &:hover {
    color:#ffa242;
  }
}

p {
  a {
    font-weight: 800;
    padding: 6px;
    border-radius: 4px;
    background: #3aa2ff;

    &:hover {
      color: #fff;
      background: #146fc4;
    }
  }
}

.quote {
  font-size: 20px;
  font-style: italic;
  margin: 20px 0;
  padding: 10px 20px;
  border-left: 4px solid darkgray;
}