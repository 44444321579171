

.header {
  $font-color: #2c3e50;

  .mobileheader {
    display: none;
    @media (max-width: 640px) {
      display: initial;
    }
  }

  .desktopheader {
    @media (max-width: 640px) {
      display: none;
    }
  }

  .bannerImage {

    width: 100%;
    height: auto;
  }

  .topLevelContainer {
    z-index: 100;
    padding: 0;
    margin: 0;
    display: grid;
  }

  .stickyContent {
    @media (max-width: 640px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .stickyContainer {
    background: #2c3e50;

    @media (max-width: 640px) {
      h2 {
        font-size: 32px;
      }

      h3 {
        display: none;
      }
    }
  }

  .navListSticky {
    z-index: 100;
  }

  h2 {
    font-size:64px;
    color: $font-color;
    margin: 0;
  }

  h3 {
    font-size: 30px;
    color: $font-color;
    margin: 0;
  }
}